<template lang="html">

  <section class="memories">
    <hero-global img="/img/hero/memorias.svg" title="Memorias" subtitle="Memorias Anuales" />
    <section class="menu-anclas container">
      <router-link to="" @click="scrollSection('memorias-anuales')" class="btn btn-tertiary">Memorias Anuales</router-link>
      <router-link to="#memorias-rse-d" @click="scrollSection('memorias-rse-d')" class="btn btn-tertiary">Memorias RSE-D</router-link>
      <router-link to="#documentacion" @click="scrollSection('documentacion')" class="btn btn-tertiary">Documentación</router-link>
    </section>
    <section id="memorias-anuales" class="container">
      <div :class="[
          showFeatureAllMemoriesAnual
            ? 'boton-features-div'
            : 'boton-features-div inactive'
        ]">
        <template v-if="showFeatureAllMemoriesAnual">
          <p class="title -intro features contraer">Contraer todo</p>
        </template>
        <template v-else>
          <p class="title -intro features contraer">Expandir todo</p>
        </template>
        <div class="boton-azul" @click="toggleFeatureMemoriesAnual">
          <div class="boton-circulo"></div>
        </div>
      </div>
      <ul class="list-item-dates">
        <template v-for="(year, key) in Object.values(arrayCompletYearsAnnual)" :key="key">
          <li class="item-list" v-if="getDataMemoriaAnnual(year).items">
            <span class="date">{{year}}</span>
            <div class="content-date">
              <p class="title" @click="toggleFeatureItem(year)">Memoria de Actividades del CERMI Estatal {{year}}</p>
              <div v-if="getDataMemoriaAnnual(year).items" :class="[showFeatureAllMemoriesAnual ? 'feature list-download' : 'feature list-download inactive']" :data-year="year">
                <a v-for="(file, key) in getDataMemoriaAnnual(year).items" :key="key" :href="file.file.url" target="_blank" title="Descargar archivo" :download="file.name" :data-type="file.file.type">{{file.name}}</a>
              </div>
            </div>
          </li>
        </template>
        <div class="column-center">
          <button class="btn-white" @click="getMoreYearAnnual" v-if="showMoreMemoriesAnual" title="Ver más" alt="Ver más"> Ver más</button>
        </div>
      </ul>
    </section>
    <section id="memorias-rse-d" class="container">
      <div class="column-center">
        <p class="title-decoration-bottom text-center font-family-frank-ruhl ">Memorias RSE-D</p>
      </div>
      <div :class="[
                showFeatureAllMemoriesRse
                  ? 'boton-features-div'
                  : 'boton-features-div inactive'
              ]">
        <template v-if="showFeatureAllMemoriesRse">
          <p class="title -intro features contraer">Contraer todo</p>
        </template>
        <template v-else>
          <p class="title -intro features contraer">Expandir todo</p>
        </template>
        <div class="boton-azul" @click="toggleFeatureMemoriesRse">
          <div class="boton-circulo"></div>
        </div>
      </div>
      <ul class="list-item-dates">
        <template v-for="(year, key) in Object.values(arrayCompletYearsRSE)" :key="key">
          <li class="item-list" v-if="getDataMemoriaRSE(year).items">
            <span class="date">{{year}}</span>
            <div class="content-date">
              <p  class="title" @click="toggleFeatureItem('rse-{{year}}')">Memoria RSE-D CERMI {{year}}</p>
              <div :class="[showFeatureAllMemoriesRse ? 'feature list-download' : 'feature list-download inactive']" :data-year="'rse-'+year" v-if="getDataMemoriaRSE(year).items">
                <a v-for="(file, key) in getDataMemoriaRSE(year).items" :key="key" :href="file.file.url" target="_blank" title="Descargar archivo" :download="file.name" :data-type="file.file.type">{{file.name}}</a>
              </div>
            </div>
          </li>
        </template>
        <div class="column-center">
          <button class="btn-white" @click="getMoreYearRSE" v-if="showMoreMemoriesRse" title="Ver más" alt="Ver más"> Ver más</button>
        </div>
      </ul>
    </section>
    <section id="documentacion" class="container shadow container-documentacion" v-if="store.otherMemories">
      <p class="title-grand">Documentación</p>
      <a v-for="(file, key) in store.otherMemories" :key="key" :href="file.file.url" target="_blank" title="Descargar documentación" :download="file.name" :data-type="file.file.type">{{file.name}}</a>
    </section>
    <carrousel-2 class="container m-bottom-40-px" :dataContent="$tm('translations.carrouselData.cermi')"/>
    <other-links-interest class="links-interest-box container" />

  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'memories',
    props: [],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      Carrousel2
    }, 
    setup() {
      const store = contentStore();
      store.loadOtherMemories()
      return {
        store
      }
    },
    mounted() {
      this.arrayCompletYearsAnnual = this.arrayYearsAnnual
      this.arrayCompletYearsRSE = this.arrayYearsRSE
      this.store.loadMemoriesAnnual({ arrayYears: this.arrayYearsAnnual, maxYear: this.maxYearAnnual })
      this.store.loadMemoriesRSE({ arrayYears: this.arrayYearsRSE, maxYear: this.maxYearRSE })
    },
    data() {
      return {
        showFeatureAllMemoriesAnual: true,
        showFeatureAllMemoriesRse: true,
        showMoreMemoriesAnual: true,
        showMoreMemoriesRse: true,
        maxYearAnnual: new Date().getFullYear(),
        minYearAnnual: 2001,
        minYearRSE:2018,
        arrayCompletYearsAnnual: [],
        maxYearRSE: new Date().getFullYear(),
        arrayCompletYearsRSE: [],
      }
    },
    methods: {
      toggleFeatureMemoriesAnual: function () {
        this.showFeatureAllMemoriesAnual = !this.showFeatureAllMemoriesAnual
      },
      toggleFeatureMemoriesRse: function () {
        this.showFeatureAllMemoriesRse = !this.showFeatureAllMemoriesRse
      },
      toggleFeatureItem: function (val) {
        if (val != null) {
          document.querySelector('.feature[data-year="' + val + '"]').classList.toggle("inactive")
        }
      },
      scrollSection: function (params) {
        if (params) {
          const offsetTop = document.getElementById(params).offsetTop - 150;
          setTimeout(function () {
            scroll({
              behavior: "smooth",
              top: offsetTop,
            });
          }, 200);
        }
      },
      range: function range(start, stop = undefined, step = 1) {
        const startArray = stop === undefined ? 0 : start;
        const stopArray = stop === undefined ? start : stop;
        return Array.from({ length: (stopArray - startArray) / step + 1 }, (_, i) => startArray + (i * step)).reverse();
      },
      getMoreYearAnnual: function name(maxyear) {
        this.maxYearAnnual = this.maxYearAnnual - 5
        // this.arrayCompletYearsAnnual = this.arrayCompletYearsAnnual.concat(this.arrayYearsAnnual)
        if (this.maxYearAnnual <= 2004 ) {
          this.showMoreMemoriesAnual = false
        }
        this.store.loadMemoriesAnnual({ arrayYears: this.arrayYearsAnnual, maxYear: this.maxYearAnnual })
      },
      getDataMemoriaAnnual: function (year) {
        if (this.store.memoriesAnnual[year] && this.store.memoriesAnnual[year].items.length > 0) {
          return this.store.memoriesAnnual[year]
        }
        return "Lo sentimos no hay resultados"
      },
      getMoreYearRSE: function name(maxyear) {
        this.maxYearRSE = this.maxYearRSE - 5
      
        // this.arrayCompletYearsRSE = this.arrayCompletYearsRSE.concat(this.arrayYearsRSE)
        if (this.minYearRSE <= 2019) {
          this.showMoreMemoriesRse = false
        }
        this.store.loadMemoriesRSE({ arrayYears: this.arrayYearsRSE, maxYear: this.maxYearRSE })
      },
      getDataMemoriaRSE: function (year) {
        if (this.store.memoriesRSE[year] && this.store.memoriesRSE[year].items.length > 0) {
          return this.store.memoriesRSE[year]
        }
        return "Lo sentimos no hay resultados"
      }

    },
    computed: {
      arrayYearsAnnual() {
        return this.range(this.minYearAnnual, this.maxYearAnnual)
      },
      // minYearAnnual() {
      //   if (this.minYearAnnual <= 2003) {
      //     return 2001
      //   } else {
      //     return this.maxYearAnnual - 4
      //   }
      // },
      arrayYearsRSE() {
        return this.range(this.minYearRSE, this.maxYearRSE)
      },
      // minYearRSE() {
      //   if (this.minYearRSE <= 2019) {
      //     return 2018
      //   } else {
      //     return this.maxYearRSE - 4
      //   }
      // },
    }
  }


</script>

<style scoped lang="scss">
  .memories {
    .hero-content {
      height: 320px;
    }

    .container-documentacion {
      margin-bottom: 60px;
    }

    .title-decoration-bottom {
      font-weight: 600;
    }
  }
</style>